.contact_section{
    background: #060E58 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 2rem 0;
    margin: 0 1rem 5rem 1rem;
    .row{
        align-items: center;
        .text1{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            color: #F8F8F9;
        }
        .text2{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 0px;
            color: #F8F8F9;
        }
      
        .btnenvoyer{
            background: #F78323;
            border: none;
            color: #F4F5FB;
            font-size: 14px;
            padding: 8px 40px;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            // border-radius: 15px;
        }
    }
    
}

.form-control {
        color: #060E58;
        background-color: white;
        border-radius: 0;
        border: none;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-bottom: 1rem;
        
        &.formation{
            background-color: #030B51;
        }
}

.form-control:focus{
    background-color: white;
    box-shadow: none;
    color: #030B51;
    border:none;
}
// .form-control:focus {
//     outline: none;
//     box-shadow: none;
//     background-color: transparent;
//         border: none;
//         border-bottom: 2px solid $secondary;
//         margin-bottom: 2rem;
// }

@media screen and (max-width: 992px){
    .contact_section{
        .row{
            .text1{
                font-size: 25px;
            }
            .text2{
                font-size: 17px;
            }
        }
    }
}