.nav-link {
    color: inherit;
    &:hover{
    color: inherit;

    }
    &:target{
        color: inherit;
    
        }
}
.navbar{
  z-index: 10058;
}
.mynavlink{
    font-family: "Metropolis Light";
    color: $primary;
  font-size: 1.2rem;
}

.dropdown-menu.show {
  display: block;
  overflow: hidden;
}

.topHeader {
  position: relative;
}
.offcanvas-header{
  z-index: 10051;

}
.drop_formations{
  top: 0rem;
  left: 0;
  width: 100%;
  position: fixed;
  max-height: 100vh;
  overflow-y: scroll;
  z-index: 10052;
  padding: 7rem 2rem;
  background-color: #fff;
  transform: translateY(-100%);
  transition: .5s;

  button {
    margin-top: -5rem ;
    float: right;
    background-color: $primary;
    color: #fff;
  }

  .title {
    border-bottom: 1px solid $secondary;
    h6 {
      color: $primary;
      font-size: 16px;
    }
    
  }
  ul{
    margin-top: 1.5rem;
    padding-left: 0;
    list-style: none;
    li {
      font-size: 12px;
      padding: .4rem 0;
      overflow: hidden;
      position: relative;
      a {
        padding: .4rem 0;
    }
    } 
    
  }
}
.drop_formations.show{
  transform: translateY(0);
}
.megaMenu{
  transition: .5s;
}
.megaMenu.show::after{
  display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-bottom: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-top: 0;
    border-left: 0.3em solid transparent;
}
.megaMenu::after{
  display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.mylink{
  font-family: 'Montserrat';
  color: #0E176C;
  font-weight: 500;
  font-style: normal;
}
.btncertyup{
  background: #1A2588;
  border: none;
  color: #F4F5FB;
  font-size: 11px;
  padding: 6px 18px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  border-radius: 15px;
}
.btnfindyup{
  // a enlever apres
  position: relative;
  overflow-x: hidden;
  z-index: 1;
  .findy{
    z-index: 2;
    color: #060E58;
    top: 0;
    bottom: 0;
    left: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 15px;
    transition: .5s;
    transform: translateX(-100%);
  }
  &:hover{
    .findy{
      transform: translateX(0) !important;
  }
  }
  //---
  background: #F78323;
  border: none;
  color: #F4F5FB;
  font-size: 11px;
  padding: 6px 18px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  border-radius: 15px;
}

@media (max-width: 768px){
.navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
    width: 300px;
}
.navbar{
  z-index: 100;
}
}


