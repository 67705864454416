
   
    .offre_section{
        overflow-x: hidden;
        background-color: #F7F8FB;
        // padding: 2rem 0; 
        // min-height: 100vh;
        .titre{
            margin-top: 50px;
            margin-bottom: 70px;
            font-family: 'Montserrat Bold';
         
            color: #030B51;
            font-size: 2rem;
        }
        
        .text1{
            font-family: 'Montserrat';
            font-weight: 700;
            color: #030B51;
            font-size: 1.6rem;
            line-height: 40px;
        }
        .text2{
            font-family: 'Montserrat';
            font-weight: 700;
            color: #F78323;
            font-size: 1.6rem;
            line-height: 40px;
            // margin-bottom: 30px;
        }
        .text3{
            font-family: 'Montserrat';
            font-weight: 400;
            color: #030B51;
            // color: #6C757D;
            
        }
        .text4{
            font-family: 'Montserrat';
            font-weight: 400;
            color: #6C757D;
        }
        .blocbouton{
            margin-top: 10px;
            background-color: transparent;
            border-radius: 30px;
            border: 1px solid rgba(0, 0, 0, 0.13);
            padding: 3px;
        }
       
        .btnformule{
            border: none;
            background: transparent;
            font-family: 'Montserrat SemiBold';
            color: #666666;
        }
        .boutonActif {
            border: none;
            font-family: 'Montserrat SemiBold';
            padding: 11px 20px 11px 20px;
            justify-content: center;
            align-items: center;
            color: #666666;
            border-radius: 800px;
            background: #FFF;
            box-shadow: 0px 7px 29px 0px rgba(107, 107, 114, 0.20);
          }
        .cardabonnement{
            border-radius: 5px;
            background: linear-gradient(180deg, #123075 0%, #092056 100%);
            box-shadow: 40px 40px 80px 0px rgba(0, 0, 0, 0.10);
        }
        .sec1{
            // margin-top: 50px;
          
        }
        
        .titredescription{
            font-family: 'Montserrat Regular';
            color: #FFFFFF;
        }
        .description{
            font-family: 'Montserrat Regular';
            color: #709BE7;
        }
        .prix{
            // margin-top: -15px;
            font-family: 'Montserrat Bold';
            color: #FFFFFF;  
        }
        .fcfa{
            color: #709BE7;
            font-family: 'Montserrat Regular';
            font-size: 1rem;

        }
        .btnformer{
            border-radius: 5px;
            background: linear-gradient(225deg, #F78323 0%, #DC3545 100%);
            box-shadow: 20px 20px 60px 0px rgba(0, 0, 0, 0.40);
            color: white;
            border: none;
            padding: 5px 10px;
            font-family: 'Montserrat Medium';
        }
        .cardstyle{
            border: 0px solid #FFF;
            background: #FFF;
            box-shadow: none;
            
        }
        .texteforme{
            font-family: 'Montserrat SemiBold';
            font-size: .9rem;
            color: #030B51;
            border: none;
            &::before{
              content:"";
              top: 50%;
              bottom: 50%;
              width: 82%;
              right: 0;
              height: 0.5px;
              background: #F78323;
              position: absolute;
      
            }
            
          }
          .form-control{
            border-radius: 5px;
            border: 1px solid #DDDFE7;
            font-family: 'Montserrat Regular';
            color: #595F6F;
          }
          .btnformer2{
            border: none;
            border-radius: 5px;
            background: linear-gradient(180deg, #123075 0%, #092056 100%);
            // box-shadow: 20px 20px 60px 0px rgba(0, 0, 0, 0.40);
            color: #FFF;
            text-align: center;
            font-family: 'Montserrat Medium';
            padding: 10px 50px;
          }
    }

    .topabonnement {
        margin-top: 5rem;
        padding: 5rem 2rem;
        background: linear-gradient(225deg, #F78323 0%, #DC3545 100%);
        .duree{
          color: $primary;;
        }
        .title{
            margin-top: 3rem;
            .formationname{
               font-family: 'Montserrat';
               font-weight: 700;
               color: $grey;
            }
            .style{
                font-family: 'Montserrat';
                color: $grey;
            }
            .certfication{
                
                width: 350px;
                max-width: 100%;
                border-radius: 15px;
                background-color: $secondary;
                .styletexte{
    
                    text-align: left;
                    font-family: 'Montserrat';
                    font-size: .9rem;
                    font-weight: bold;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                   list-style-type: none;
                }
            }
        }
        .row{
            .card-body{
                background-color: white;
                border-radius: 1rem;
            }
           
    
            
        }
        
        
        
    }
    .row{
        .liste{
            padding: 10px 5px;
            border-radius: 5px;
            transition: .5s;
        }
        .liste:hover{
            background-color: $primary;
            
            
            .texte{
                color: white !important;
            }
          
        }
        .objectifs{
          font-family: 'Montserrat';
          color: $primary;
          font-size: 1.5rem;
          
        }
        .textebas{
            color: $primary;
            font-family: 'Montserrat Bold';
            font-size: .8rem;
        }
        // .itemsobjectifs{
        //     list-style : "square";
        //     list-style-type:"none";
        //     font-family:"Montserrat";
        //     font-size:"15px";
            
        //   }
    }
    @media (max-width: 600px){
        .row{
            .objectifs{
                font-size: 1rem;
            }
            .cardinscription{
                margin-top: 0px;
            }
        }
    }
    @media (max-width: 768px) {
        .offre_section {
            .titre{
                margin-top: 40px;
                 margin-bottom: 40px;
            }
            .text1 , .text2{
                font-size: 1.2rem;
                line-height: 30px;
            }
            .blocbouton{
                margin-bottom: 40px;
            }
            .secprix{
                margin-top: 15px;
            }
            .texteforme{
                
                font-size: .8rem;
            
                border: none;
                &::before{
           
                 
                  width: 45%;
                
                  
          
                }
            }
        }
            
    }