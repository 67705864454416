.header_section {
  // background-image: url("../../public/img/Header_bg-03.svg");
  background: linear-gradient(
    228.36deg,
    rgba(3, 17, 153, 0.851) 4.45%,
    #02094d 93.36%
  );
  padding: 8rem 2rem 0 2rem;
  overflow-x: hidden;

  .text {
    h1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 1.2;
      color: #f4f5fb;
    }
    .cv {
      span {
        color: #f78323;
        position: relative;
        background: url("../../public/img/cv_svg.svg");
        background-repeat: no-repeat;
        background-position: bottom;
        padding-bottom: 5px;
       
      }
      
    }
    .certif {
      span {
        color: #f78323;
        position: relative;
        background: url("../../public/img/certif_svg.svg");
        background-repeat: no-repeat;
        background-position: bottom;
        padding-bottom: 15px;
       
      }
      
    }
  }
  .row {
    align-items: center;
    position: relative;
  }
  .textsection {
    z-index: 1;
  }
  .imgsection {
    z-index: 2;
  }
  .textsection .text {
    // padding-left: 188px;

    .bloc1,
    .bloc2 {
      margin-bottom: 20px;
    }
    .bloc3 {
      margin-bottom: 30px;
    }

    .cadre {
      display: flex;
      //justify-content: flex-start;
      align-items: center;
    }

    .text-1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 50px;
      color: #f4f5fb;
    }

    .text-1-1 {
      font-size: 65px;
      z-index: 1;
      //position: absolute;
    }

    .nn {
      border: 2px solid #f78323;
      display: inline;
      padding: 29px 109px;
      border-radius: 78px;
      position: absolute;
    }

    .nn::after {
      content: "";
      bottom: 17px;
      right: -9px;
      position: absolute;
      z-index: 2;
      width: 15px;
      height: 15px;
      border-radius: 100%;
      background: linear-gradient(
        to right,
        rgba(21, 31, 125, 0) 0%,
        rgba(21, 31, 125, 1) 50%,
        rgba(21, 31, 125, 0) 100%
      );
    }
    .text-2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 0.9rem;
      line-height: 14px;
      .style1 {
        color: #eeeff5;
      }
      .style2 {
        color: #f78323;
      }
    }
    .text-3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      color: #f4f5fb;
      font-size: 1.2rem;
    }
    .search-container {
      position: relative;
      display: flex;
      align-items: center;
    }

    .search-input {
      padding-left: 10px;
      border: none;
      border-radius: 20px;
      width: calc(100% - 90px);
      max-width: 400px;
      height: 45px;
      box-sizing: border-box;
    }

    .search-button {
      font-size: 10px;
      width: 80px;
      padding-top: 5px;
      padding-bottom: 5px;
      border: none;
      border-radius: 20px;
      background-color: #101971;
      color: white;
      cursor: pointer;
      margin-left: -90px;
    }

    .search-button i {
      /* Styles pour l'icône de recherche (utilisation de Font Awesome ici) */
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
  }

  .img {
    z-index: 2 !important;
  }
}

@media (max-width: 992px) {
  .header_section {
    padding: 8rem 1rem 0 1rem;

    .textsection {
      .text .text-1-1 {
        font-size: 50px;
        z-index: 1;
      }
      .text-1 {
        .nn {
          padding: 22px 68px;
        }

        .nn:after {
          bottom: 11px;
          right: -8px;
          width: 10px;
        }
      }
    }
  }
  #flip {
    font-size: 2rem !important;
  }
}
@media (max-width: 425px) {
  .text {
h1 {
  font-size: 38px !important;
}
}
  .header_section {
    padding: 8rem 1rem 0 1rem;

    .text-1 {
      .nn {
        padding: 18px 58px;
      }

      .nn:after {
        bottom: 10px;
        right: -8px;
        width: 11px;
      }
    }

    .textsection .text .text-1-1 {
      font-size: 42px;
    }
  }
  #flip {
    font-size: 2rem !important;
  }
}

#flip {
  height: 70px;
  overflow: hidden;
  font-size: 3rem;
  font-weight: bold;
  text-transform: uppercase;
}

#flip > div > div {
  // padding: 4px 12px;
  height: 50px;
  margin-bottom: 45px;
  display: inline-block;
}
#flip div:first-child {
  animation: show 3s linear infinite;
}

#flip div div {
  // background: #42c58a;
  color: $secondary;
}
//   #flip div:first-child div {
//     background: #4ec7f3;
//   }
//   #flip div:last-child div {
//     background: #DC143C;
//   }
@keyframes show {
  0% {
    margin-top: -270px;
  }
  5% {
    margin-top: -180px;
  }
  33% {
    margin-top: -180px;
  }
  38% {
    margin-top: -90px;
  }
  66% {
    margin-top: -90px;
  }
  71% {
    margin-top: 0px;
  }
  99.99% {
    margin-top: 0px;
  }
  100% {
    margin-top: -270px;
  }
}
