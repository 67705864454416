.main {
  justify-content: center;
  background-color: #F7F8FB;
}
.tagcloud {
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  font-weight: 650;
  //   margin-left: 30%;
  margin: auto;
}
.tagcloud--item {
  color: #1313c7;
}

.tagcloud--item:hover {
  color: #c79b23;
}

@media (max-width: 800px) {
  .main.wcloud {
    margin-left: -5rem;
  }
  .tagcloud {
    margin: 0%;
  }
  .wcloud h1 {
    margin-left: auto;
  }
}
