.mecertifier {
    .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        text-align: center;
        img {
            max-width: 100%;
        }
    }

    .text {
        h1 {
            font-size: 2.5rem;
            font-weight: 700;
            color: $primary;
            span {
                color: $secondary;
            }
        }

        .content {
            font-size: 16px;
            color: #000;
            padding: 1rem 0;

            p span {
                font-weight: 700;
                padding-top: 2rem;
            }
        }
    }
}

@media (max-width: 425px) {
    .mecertifier {
        .text {
            margin-top: 1rem;
            h1 {
                font-size: 1.6rem !important;
            }

        }
    }
}