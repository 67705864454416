.topformation {
    margin-top: 5rem;
    padding: 5rem 2rem;
    background: linear-gradient(228.36deg, rgba(3, 17, 153, 0.851) 4.45%, #02094D 93.36%);
    //    background-image: url("../../public/img/Header_bg-03.svg");
    //     background-size: cover;
    //     background-position: center;
    //     background-repeat: no-repeat;
    //     z-index: 1;
    .title{
        margin-top: 3rem;
        .formationname{
           font-family: 'Montserrat';
           font-weight: 700;
           color: $grey;
        }
        .style{
            font-family: 'Montserrat';
            color: $grey;
            
        }
        .certfication{
            
            width: 350px;
            max-width: 100%;
            border-radius: 15px;
            background-color: $secondary;
            .styletexte{

                text-align: left;
                font-family: 'Montserrat';
                font-size: .9rem;
                font-weight: bold;
                letter-spacing: 0px;
                color: #FFFFFF;
               list-style-type: none;
            }
        }
    }
    .row{
        .card-body{
            background-color: white;
            border-radius: 1rem;
        }
       

        
    }
      
}
.row{
    .liste{
        padding: 10px 5px;
        border-radius: 5px;
        transition: .5s;
    }
    .liste:hover{
        background-color: $primary;
        
        
        .texte{
            color: white !important;
        }
      
    }
    .objectifs{
      font-family: 'Montserrat';
      color: $primary;
      font-size: 1.5rem;
      
    }
    .textebas{
        color: $primary;
        font-family: 'Montserrat Bold';
        font-size: .8rem;
    }
    // .itemsobjectifs{
    //     list-style : "square";
    //     list-style-type:"none";
    //     font-family:"Montserrat";
    //     font-size:"15px";
        
    //   }
}
.listinfo{
    background-color: #cddbff;
    border-radius: 6px;
    padding: 15px;
    margin: 20px;

}


@media (max-width: 600px){
    .row{
        .objectifs{
            font-size: 1rem;
        }
        .cardinscription{
            margin-top: 0px;
        }
    }
}