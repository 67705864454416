.domaine_section {
  overflow-x: hidden;
  // background: #F4F5FB 0% 0% no-repeat padding-box;
  background: #fff 0% 0% no-repeat padding-box;

  .titleSection {
    background-image: url("../../public/img/fondjemeforme.svg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .texte1 {
      color: #101971;
      font-family: "Montserrat";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 700;
    }
    .texte2 {
      color: #101971;
      font-family: "Montserrat";
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 700;
      line-height: 0px;
      .texte2c {
        color: #f78323;
      }
    }
    .texte3 {
      color: #101971;
      font-family: "Montserrat";
    }
  }
  .row {
    padding-left: 2rem;
    padding-right: 2rem;
    // margin-top: -50px;
    z-index: 1;
    border-radius: 1rem;
    font-family: "Metropolis Regular";
    color: $primary;
    justify-content: center;
    // text-align: justify;
    align-items: center;
    .domaine_item {
      padding: 2rem 1rem;
      // transition: .5s ease-in-out;
      position: relative;
      overflow: hidden;
      border-radius: 5px;
      .more {
        bottom: 0.5rem;
        display: block;
        position: absolute;
        width: 100%;
        margin: 0;
        padding: 0;
        left: 0;
        color: $secondary;
        font-size: 1rem;
        // transform: translateY(200%);
        // transition: .5s;
      }
      // &:hover{
      //     // background-color: $primary;
      //     // transform: scale(1.1);
      //     // color: $white;
      // //     .more {
      // //     transform: translateY(0%);
      // // }
      // }
    }

    span {
      font-size: 1.2rem;
      font-family: "Metropolis Medium";
    }
    .icon {
      margin-bottom: 1rem;
    }
    .icon img {
      width: 81px;
    }
    .cardformation {
      border: none;
      border-radius: 1rem;
      position: relative;
      width: 300px;
      &:hover {
        background-color: #101971;
        transform: scale(1.05);
        color: $white;
        transition: 2.5s;

        .more {
          transform: translateY(0%);
        }
        .nomformation {
          color: white;
          transition: 2.5s;
        }
        .cerclefleche {
          background-color: white;
          transition: 2.5s;
        }
        .fleche {
          color: #060e58;
          transition: 2.5s;
        }
      }
      .cardinfo {
        position: relative;
        height: 200px;
        width: 299px;
      }
    }
    // .cardformation{
    //     position: relative;
    //     border: 1px solid white  ;
    //     border-radius: 1rem;
    //     z-index: 1;
    //     .avenir{
    //         z-index: 2;
    //         color: #060E58;
    //         top: 0;
    //         bottom: 0;
    //         position: absolute;
    //         height: 100%;
    //         width: 100%;
    //         background-color: white;
    //         transition: 1s;
    //         transform: translateY((-100%));
    //     }
    //     &:hover{
    //         .avenir{
    //             transform: translateY(0) !important;
    //         }
    //     //     background-color: #101971;
    //     //     transform: scale(1.05);
    //     //     color: $white;
    //     //     transition: 2.5s;

    //     //     .more {
    //     //     transform: translateY(0%);

    //     // }
    //     // .nomformation{
    //     //     color: white;
    //     //     transition: 2.5s;
    //     // }
    //     // .cerclefleche{
    //     //     background-color: white;
    //     //     transition: 2.5s;
    //     // }
    //     // .fleche{
    //     //     color: #060E58 ;
    //     //     transition: 2.5s;
    //     //   }
    //     }
    // }
    .nomformation {
      text-align: left;
      font: normal normal 700 0.9rem Montserrat;
      letter-spacing: 0px;
      color: #060e58;
      opacity: 1;
    }
    .cerclefleche {
      width: 40px;
      height: 40px;
      background-color: white;
      border-radius: 50%;
      padding: 10px;
    }
    .fleche {
      color: white;
    }
    .textbloc1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;

      color: #060e58;
    }
    .textbloc2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #060e58;
      .lafleche {
        width: 35px;
      }
    }
    .btnjemeforme {
      background: #1a2588;
      border: none;
      color: #f4f5fb;
      font-size: 14px;
      padding: 8px 30px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      border-radius: 15px;
    }
  }
}

@media (max-width: 768px) {
  .domaine_section {
    text-align: center;
    .row {
      justify-content: space-around;
      padding: 2rem 0;
      .icon img {
        width: 50px;
      }
    }
    .row .col {
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 425px) {
  .domaine_section {
    .row {
      .cardformation {
        width: 350px;
        .cardinfo {
            
            width: 350px;
          }
      }
    }
  }
}
