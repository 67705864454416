.certifier_section {
  overflow-x: hidden;
  background-color: white;
  .jemecertifie {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 55px;
    color: #030b51;
  }
  .row {
    .text1 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 40px;
      color: #030b51;
    }
    .text2 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 1.6rem;
      line-height: 40px;
      color: #f78323;
    }
    .text3 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;

      color: #030b51;
    }
    .text4 {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 16px;
      color: #030b51;
    }
    .btnjemecertifie {
      background: #1a2588;
      border: none;
      color: #f4f5fb;
      font-size: 14px;
      padding: 8px 35px;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      border-radius: 15px;
    }
  }
}

@media (max-width: 425px) {
  .certifier_section {
    .row {
      .cardimg {
        margin: auto;
        width: 95%;
      }
    }
  }
}
