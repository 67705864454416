@font-face {
    font-family: "Metropolis Bold";
    src: url("../../public/fonts/Metropolis-Bold.otf") format("opentype")
  }
  @font-face {
    font-family: "Metropolis Light";
    src: url("../../public/fonts/Metropolis-Light.otf") format("opentype")
  }

  @font-face {
    font-family: "Metropolis Medium";
    src: url("../../public/fonts/Metropolis-Medium.otf") format("opentype")
  }

  @font-face {
    font-family: "Metropolis Regular";
    src: url("../../public/fonts/Metropolis-Regular.otf") format("opentype")
  }
  @font-face {
    font-family: "Arial Black";
    src: url("../../public/fonts/ArialCEMTBlack.ttf") format("opentype")
  }
  @font-face {
    font-family: "Montserrat";
    src: url("../../public/fonts/Montserrat.ttf") format("opentype")
  }
  @font-face {
    font-family: "Montserrat Bold";
    src: url("../../public/fonts/Montserrat-Bold.ttf") format("opentype")
  }
  @font-face {
    font-family: "Montserrat SemiBold";
    src: url("../../public/fonts/Montserrat-SemiBold.ttf") format("opentype")
  }
  @font-face {
    font-family: "Montserrat Regular";
    src: url("../../public/fonts/Montserrat-Regular.ttf") format("opentype")
  }
  @font-face {
    font-family: "Montserrat Medium";
    src: url("../../public/fonts/Montserrat-Medium.ttf") format("opentype")
  }
h1, h2, h3 {
    font-family: 'Metropolis Bold';
    margin-bottom: 0;
}

a{
    color: inherit;
    text-decoration: none;
}
a:hover{
    color: inherit;
    text-decoration: none;
}
body{
    position: relative;
}
.btn {
    border-radius: 10px;
    padding: .5rem 2rem;
    border: none;
}
.btn-secondary {
    color: #FFF;
}
.btn-secondary:hover {
    color: #FFF;
}
.btn-secondary {
    color: #FFF;
    position: relative;
    z-index: 1;
    overflow: hidden;
}
.btn-secondary::after{
    content: '';
    width: 100%;
    height: 100%;
    background: $primary;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    box-sizing: border-box;
    z-index: -1;
    transform: translateX(-100%);
    transition: .5s;
}
.btn-secondary:hover::after{
    transform: translateX(0);
}
.btn-primary{
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: $secondary;
    
}
// .btn-primary::after {
//     content: '';
//     width: 100%;
//     height: 100%;
//     background: $secondary;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     position: absolute;
//     box-sizing: border-box;
//     z-index: -1;
//     transform: translateX(-100%);
//     transition: .5s;
// }
// .btn-primary:hover::after{
//     transform: translateX(0);
// }






.mylink {
    position: relative;
    margin: auto 0;
    padding:  auto 2rem 2rem 2rem !important;
    overflow: hidden;
    transition: .5s;
}
.mylink::before{
    content: '';
    background-color: $primary;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    position: absolute;
    transform: translateX(-100%);
    transition: .5s;
}
.mylink:hover.mylink::before{
    transform: translateX(0);
}



.titleSection h2{
    color: $primary;
    padding: 2rem 0;
}