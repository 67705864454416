.textannimate{
    position: relative;
}

.textannimate .text{
    position: relative;
    color: $secondary;
    font-size: 2.5rem;
}
.text.sec-text{
    font-family: "Metropolis Bold" !important;
}
.textannimate .text.first-text{
    color: #FFF;
}
.text.sec-text:before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    z-index: 0;
    // border-left: 2px solid $secondary;
    animation: animate 4s steps(12) infinite;
}
@keyframes animate{
    40%, 60%{
        left: calc(100% + 4px);
    }
    100%{
        left: 0%;
    }
}

@media (max-width: 992px) {
    .textannimate .text{
        font-size: 2rem;
    }
        
}
@media (max-width: 800px) {
    .textannimate .text{
        font-size: 1.6rem;
    }
        
}