.accordion {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  width: 100%;

  .accordion-item {
    margin: 10px;
    border: 1px solid rgb(247, 131, 35);
    border-radius: 0px;
    position: relative;

    .accordion-img {
    }

    .accordion-button:focus {
      box-shadow: none;
    }
    .accordion-button:not(.collapsed) {
      background-color: none;
    }

    .accordion-body {
      .list-group-item {
        display: flex;
        align-items: center;
        border: none;
      }
    }
  }
}
