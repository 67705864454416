.bosser_section{
    overflow-x: hidden;
    overflow-y: hidden;
    .bloc2{
        background-image: url("../../public/img/fondjemeforme.svg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .row{
        .text1{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 1.6rem;
            color: #030B51;
        }
        .text2{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 40px;
            color: #030B51;
            .style1{
                color: #F78323;
            }
        }
        .text3{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            color: #757693;
        }
        .text4{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 1rem;
            line-height: 16px;
            color: #757693;
        }
        .bloc1{
            background-image: url("../../public/img/imagejebosse.png");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            max-width: 100%;
            min-height: 50vh;
           
            
            .logo-button {
                position: absolute;
                top: 50%;
                left: 52%;
                transform: translate(-50%, -50%);
                text-align: center;
              }
              
              .logo {
                width: 100px;
                margin-bottom: 10px; 
              }
              .btnjebosse{
                 // a enlever apres
                    position: relative;
                    overflow-x: hidden;
                    z-index: 1;
                    .bosse{
                        z-index: 2;
                        color: white;
                        font-size: 12px;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        height: 100%;
                        width: 100%;
                        background-color: #F78323;
                        border-radius: 15px;
                        transition: .5s;
                        transform: translateX(-100%);
                    }
                    &:hover{
                        .bosse{
                        transform: translateX(0) !important;
                    }
                    }
                    //---
                background: #1A2588;
                border: none;
                color: #F4F5FB;
                font-size: 15px;
                padding: 10px 25px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 700;
                border-radius: 15px;

              }
              
        }
        // .ligne{
        //     border-bottom: 4px solid #F78323;
           
            
        // }
    }
    
}