.banniere {
  width: 70%;
  height: 550px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  background-image: url("../../public/img/Group3.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: white;
  position: relative;
  &::before {
    top: -10px;
    left: -10px;
    width: 50%;
    height: 50%;
    content: "";
    background-color: #f78323;
    position: absolute;
    z-index: -10;
  }

  &::after {
    bottom: -10px;
    right: -10px;
    width: 50%;
    height: 50%;
    content: "";
    background-color: #f78323;
    position: absolute;
    z-index: -10;
  }

  .banniere-h2 {
    color: rgb(247, 131, 35);
    background-color: white;
    padding: 4px 10px;
  }
  .banniere-h1 {
    font-size: 50px;
    // font-family: 'Montserrat';
  }

  .banniere-p1 {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    position: relative;
    // .nn {
    //   border: 2px solid #f78323;
    //   background-color: #f78323;
    //   display: inline;
    //   border-radius: 0px;
    //   position: absolute;
    //   padding: 2px 162px;
    //   bottom: 124px;
    // }
    &::before {
      bottom: -2px;
      left: -0;
      right: 0;
      width: 100%;
      height: 5px;
      content: "";
      background-color: #f78323;
      position: absolute;
      z-index: 1;
    }
  }

  .banniere-btn {
    margin-bottom: 30px;
  }
}
@media (max-width: 992px) {
  .banniere {
    // margin: 1rem;
    width: auto;
    // font-size: 45px;
    // font-family: 'Montserrat';

    &-h1 {
      text-align: center;
    }

    &::before {
      top: -10px;
      left: -10px;
      width: 50%;
      height: 50%;
      content: "";
      background-color: #f78323;
      position: absolute;
      z-index: -10;
    }

    &::after {
      bottom: -1-10;
      right: -10px;
      width: 50%;
      height: 50%;
      content: "";
      background-color: #f78323;
      position: absolute;
      z-index: -10;
    }
  }
}
@media (max-width: 457px) {
  .banniere {
    /* margin: 1rem; */
    width: 100%;
  }
}
