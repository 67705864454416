.inscription_section{
    overflow-x: hidden;
    // background: #f7f8fa 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 2rem 0;
    margin: 0 3rem 5rem 3rem;
   
    .row{
        .text1{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 1.3rem;
            color: $primary;
        }
        .text2{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: .9rem;
            color: $primary;
            line-height: 25px;
        }
        
    }
}

@media (max-width: 600px){
    .inscription_section{
        margin: 0 0rem 5rem 0rem;
    }
}