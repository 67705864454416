.topSection {
    margin-top: 5rem;
    padding: 5rem 2rem;
    background-image: url('../../public/img/bgtheme.svg');
    background-size: cover;
    color: $grey;
    .title{
        margin-top: 3rem;
    }
}

.orthersection{
    padding: 2rem;
    .comming{

    position: relative;
}
    .comming::after{
        content: 'A VENIR';
        border-radius: .5rem;
        font-size: .5rem;
        padding: .3rem;
        background-color: $secondary;
        color: $primary;
        top: 1.7rem;
        right: 1rem;
        position: absolute;
    }
    .col-md-6{
        position: relative;
    }
    .badge{
        top: 1.7rem;
        right: 1rem;
        position: absolute;
    }
}